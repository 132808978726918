import { useState } from 'react';

function ThreadForm({ onSubmit }) {
  const [content, setContent] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!content.trim()) return;
    onSubmit(content);
    setContent('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Start a new thread..."
      />
      <button type="submit">Create Thread</button>
    </form>
  );
}

function Post({ post, threadId, onReply }) {
  // Helper to convert >>123 into links
  const formatContent = (content) => {
    return content.replace(
      />>\d+/g,
      match => `<a href="#post-${match.slice(2)}">${match}</a>`
    );
  };

  return (
    <div className="post" id={`post-${post.id}`}>
      <div className="post-header">
        No.{post.id}
        {post.backRef && <a href={`#post-${post.backRef}`}>←</a>}
        {post.forwardRef && <a href={`#post-${post.forwardRef}`}>→</a>}
      </div>
      <div 
        className="post-content"
        dangerouslySetInnerHTML={{ __html: formatContent(post.content) }}
      />
    </div>
  );
}

function Thread({ thread, onReply }) {
  const [replyContent, setReplyContent] = useState('');

  const handleReply = (e) => {
    e.preventDefault();
    if (!replyContent.trim()) return;
    onReply(thread.id, replyContent);
    setReplyContent('');
  };

  return (
    <div className="thread">
      {thread.posts.map(post => (
        <Post 
          key={post.id} 
          post={post}
          threadId={thread.id}
          onReply={onReply}
        />
      ))}
      <form onSubmit={handleReply}>
        <textarea
          value={replyContent}
          onChange={(e) => setReplyContent(e.target.value)}
          placeholder="Reply to thread..."
        />
        <button type="submit">Reply</button>
      </form>
    </div>
  );
}

function ChanStyle() {
  const [threads, setThreads] = useState([]);
  const [nextId, setNextId] = useState(1);

  const createThread = (content) => {
    const newThread = {
      id: nextId,
      posts: [{
        id: nextId,
        content,
        backRef: null,
        forwardRef: null
      }]
    };
    setThreads([newThread, ...threads]);
    setNextId(nextId + 1);
  };

  const addReply = (threadId, content) => {
    setThreads(threads.map(thread => {
      if (thread.id !== threadId) return thread;
      
      const newPost = {
        id: nextId,
        content,
        backRef: thread.posts[thread.posts.length - 1].id,
        forwardRef: null
      };
      
      // Update forward ref of previous post
      const updatedPosts = [...thread.posts];
      updatedPosts[updatedPosts.length - 1] = {
        ...updatedPosts[updatedPosts.length - 1],
        forwardRef: nextId
      };
      
      return {
        ...thread,
        posts: [...updatedPosts, newPost]
      };
    }));
    setNextId(nextId + 1);
  };

  return (
    <div className="chan-style">
      <h1>Simple 4chan-style Threads</h1>
      <ThreadForm onSubmit={createThread} />
      {threads.map(thread => (
        <Thread
          key={thread.id}
          thread={thread}
          onReply={addReply}
        />
      ))}
    </div>
  );
}

export default ChanStyle;