import { useState } from 'react';
import './App.css';

// Post form component for creating new posts
function PostForm({ onSubmit }) {
  const [content, setContent] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!content.trim()) return;
    onSubmit(content);
    setContent('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="What's on your mind?"
      />
      <button type="submit">Post</button>
    </form>
  );
}

// Individual post component
function Post({ post, onDelete, onLike, onAddReply }) {
  const [replyContent, setReplyContent] = useState('');

  const handleReplySubmit = (e) => {
    e.preventDefault();
    if (!replyContent.trim()) return;
    onAddReply(post.id, replyContent);
    setReplyContent('');
  };

  return (
    <div className="post">
      <p>{post.content}</p>
      <button onClick={() => onLike(post.id)}>
        {post.likes > 0 ? 'Unlike' : 'Like'} ({post.likes})
      </button>
      <button onClick={() => onDelete(post.id)}>Delete</button>
      
      <form onSubmit={handleReplySubmit}>
        <input
          type="text"
          value={replyContent}
          onChange={(e) => setReplyContent(e.target.value)}
          placeholder="Write a reply..."
        />
        <button type="submit">Reply</button>
      </form>

      <div className="replies">
        {post.replies.map(reply => (
          <div key={reply.id} className="reply">
            <p>{reply.content}</p>
            <button onClick={() => onLike(post.id, reply.id)}>
              {reply.likes > 0 ? 'Unlike' : 'Like'} ({reply.likes})
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

function FacebookStyle() {
  const [posts, setPosts] = useState([]);
  const [nextId, setNextId] = useState(1);

  const addPost = (content) => {
    const newPost = {
      id: nextId,
      content,
      likes: 0,
      replies: []
    };
    setPosts([newPost, ...posts]);
    setNextId(nextId + 1);
  };

  const deletePost = (postId) => {
    setPosts(posts.filter(post => post.id !== postId));
  };

  const likePost = (postId, replyId) => {
    setPosts(posts.map(post => {
      if (post.id !== postId) return post;
      
      if (replyId) {
        // Toggle like on reply
        return {
          ...post,
          replies: post.replies.map(reply => 
            reply.id === replyId 
              ? { ...reply, likes: reply.likes > 0 ? 0 : 1 }
              : reply
          )
        };
      }
      
      // Toggle like on main post
      return { ...post, likes: post.likes > 0 ? 0 : 1 };
    }));
  };

  const addReply = (postId, content) => {
    setPosts(posts.map(post => {
      if (post.id !== postId) return post;
      return {
        ...post,
        replies: [...post.replies, {
          id: nextId,
          content,
          likes: 0
        }]
      };
    }));
    setNextId(nextId + 1);
  };

  return (
    <div className="App">
      <h1>Simple Facebook-style Posts</h1>
      <PostForm onSubmit={addPost} />
      <div className="posts">
        {posts.map(post => (
          <Post
            key={post.id}
            post={post}
            onDelete={deletePost}
            onLike={likePost}
            onAddReply={addReply}
          />
        ))}
      </div>
    </div>
  );
}

export default FacebookStyle;