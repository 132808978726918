import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import FacebookStyle from './facebook-style';
import ChanStyle from './4chan-style';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <nav>
          <Link to="/facebook-style">Facebook Style</Link> | 
          <Link to="/4chan-style">4chan Style</Link>
        </nav>
        
        <Routes>
          <Route path="/facebook-style" element={<FacebookStyle />} />
          <Route path="/4chan-style" element={<ChanStyle />} />
          <Route path="/" element={<h1>Choose a style above!</h1>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;